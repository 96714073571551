<template>
  <BaseComponent>
    <h1>Page Not Found</h1>
    <p>Sorry, this page was not found :(</p>
  </BaseComponent>
</template>

<script lang="ts" setup>
useHead({
  title: "Not Found - Dienst 3",
});

setResponseStatus(useRequestEvent(), 404, "Not Found");
</script>
